@import '../../styles/colors';

$arrowBoxSize: 2.8rem;

.dropdown-container {
    background-repeat: no-repeat;
    position: relative;
    padding: 0;
    background-image: linear-gradient(to right, #eeeeee calc(100% - #{$arrowBoxSize}), $primary $arrowBoxSize);
    border: 0;
}

.arrow {
    position: absolute;
    width: $arrowBoxSize;
    right: 0;
    bottom: 0;
    top: 0;
    background: transparent;
    padding: 4px;
    height: 100%;
}


select {

    /* styling */
    background-color: white;
    border: thin solid transparent;
    border-radius: 4px;
    display: inline-block;
    font: inherit;
    line-height: 1.5em;
    padding: 0.5em 3.5em 0.5em 1em;

    /* reset */

    margin: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
}


/* arrows */

select.dropdown {
    width: 100%;
    background: transparent;
}

.custom-select {
    position: relative;

    &.empty {
        .select-selected {
            color: $primary;
        }
    }
}

.custom-select select {
    // display: none;
    /*hide original SELECT element: */

    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    border: 0;
}


/* style the items (options), including the selected item: */
.select-selected {

    width: 100%;
    background-image: linear-gradient(to right, #eeeeee calc(100% - #{$arrowBoxSize}), $primary $arrowBoxSize);

    color: black;
    font-size: 16px;
    font-weight: 300;
    padding: 0.5rem;
    cursor: pointer;
}


/* Style the arrow inside the select element: */
.dropdown-container:after {

    position: absolute;
    content: "";
    bottom: 0;
    top: 0;
    right: 0;
    height: 100%;
    width: 2.8rem;
    pointer-events: none;
    background-size: 30px;
    background-repeat: no-repeat;
    background-image: url("../../assets/images/arrow.svg");
    background-position: center;
}

/* Point the arrow upwards when the select box is open (active): */
.dropdown-container.select-arrow-active:after {
    transform: rotate(180deg);
}

.select-items div {
    color: black;
    font-size: 14px;
    font-weight: 300;
    padding: 0.3rem;
    cursor: pointer;
}

/* Style items (options): */
.select-items {
    position: absolute;
    background-color: white;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 99;
    border: 1px solid $primary;
    max-height: 200px;
    overflow: auto;
}


/* Hide the items when the select box is closed: */
.select-hide {
    display: none;
}

.select-items div:hover,
.same-as-selected {
    background-color: rgba(0, 0, 0, 0.1);
}