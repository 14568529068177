$secondary: #ccbdbe;
$primary: #ff6600;
$primary-faded: fade-out($primary, 0.1);
$secondary-faded: #f0ebeb;

$text-color: #000000;
$text-secondary: #ffffff;
$secondary-text-color: #c9babb;

$dropdown-color: #dacece;

$brand-primary: $primary;

$modal-box-primary: #927879;
$modal-box-secondary: #A08485;
$modal-box-tertiary: #B6A0A2;

$mirror-box: #9ca6a7;
$wireframe-kit-box: #b57967;

:root {
  --primary: $primary;
  --text: white;
}
