@import '../../styles/variables';

.close-arrow {
    position: absolute;
    top: 50%;
    left: 0;
    transform: rotate(90deg) translateX(-50%);
    width: 50px;
    height: 50px;
    cursor: pointer;
}

.modal-container {
    .modal {
        .modal-content {
            height: 100%;
            padding: 4px;

            @media screen and (max-width: 575px) {
                padding-top: 10px;
            }
        }
    }
}

.download-modal-section {
    width: 100%;
    height: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .hidden-section {
    display: none;
  }
  
  .download-modal {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    padding-right: 50px;
    padding-left: 50px;
  
    a {
      color: $primary;
  
      &:hover {
        color: $primary;
        opacity: .8;
      }
    }
  
    .download-modal-text {
      padding-bottom: 10px;
    }
  
    >div {
      flex: 1 1 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-size: 0.9em;
  
  
      +div {
        margin-top: 1.5em;
      }
    }
  
    label {
      width: 6.5em;
  
      +input {
        margin-left: .35em;
      }
    }
  
  
    .button-container {
      display: flex;
      justify-content: center;
    }
  
    .button {
      display: block;
      padding: 10px;
      @media screen and (max-width: 575px) {
          margin-bottom: 20px;
      }
    }
  
    .checkbox {
      justify-content: left;
      font-weight: bold;
      padding-top: 14px;
      padding-bottom: 14px;
  
      input {
        width: auto;
      }
  
      label {
        width: auto;
        margin: 0 1em;
      }
    }
  
    button {
      background: transparent;
      border: 0;
      outline: none;
      padding-top: 14px;
  
      .text {
        font-family: Raleway;
        font-size: $text-size;
      }
    }
  
    .submit {
      margin: 1em 0;
      justify-content: center;
    }
  
    input {
      width: 100%;
    }
  
    input[name=prefix] {
      width: 4em;
      display: inline-block;
      margin-left: 0.2em;
  
      +input {
        width: calc(100% - 4.5em);
        display: inline-block;
      }
    }
  
    input[placeholder] {
      text-overflow: ellipsis;
    }
  
    &-side {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 6em;
  
      .big-ball {
        display: none;
      }
    }
  
    select,
    input {
      font-size: 16px;
    }
  
    .mandatory-field-label {
      color: red;
      font-size: 12px;
    }
  
    input {
      display: block;
      outline: none;
      color: black;
      padding: 0.5em 40px 0.5em 0.5em;
      font-weight: 300;
      border: none;
      background-color: #eeeeee;
  
      &::placeholder {
        color: #ff6600;
      }
    }
  }