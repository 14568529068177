/*html,
body,
.content-default,
.content-wrapper {
    width: 100%;
    height: auto;
    min-height: 100%;
    margin: 0;
    font-family: Raleway, Helvetica, Arial, sans-serif;
    font-weight: $medium;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @include media-breakpoint-up(md) {
        height: 100%;
        overflow: hidden;

        #barba-wrapper {
            overflow: hidden;
        }

        &.menu-open {
            overflow: hidden;
        }
    }
}*/

* {
    &::selection {
        background: fade-out($primary, 0.8);
    }

    &::-moz-selection {
        background: fade-out($primary, 0.8);
    }

    @media screen and (pointer: fine) {
        &::-webkit-scrollbar {
            position: relative;
            width: 0.7em;
            height: 0.7em;
            background-color: transparent;

            &-track {
                position: relative;
                border-radius: 0.5em;
                background-color: transparent;
            }

            &-thumb {
                border-radius: 0.5em;
                background-color: $secondary;
            }
        }
    }
}

body {
    height: 100%;
    overflow: hidden;

    .content-wrapper {
        height: 100vh;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        scroll-behavior: smooth;
    }
}

img {
    &.contain {
        font-family: "object-fit: contain; object-position: top;";
        object-fit: contain;
    }

    &.cover {
        font-family: "object-fit: cover; object-position: top;";
        object-fit: cover;
    }
}

.body-home {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
}

a,
a:hover,
a:active,
a:focus {
    color: inherit;
    text-decoration: none;
}

.content-default,
.content-wrapper {
    position: relative;
    transition: border 150ms $easeInOutQuart;
    border: 0em solid $primary-faded;
}

h1 {
    font-weight: $extrabold;
}

blockquote {
    font-weight: $thin;
    font-size: 120%;

    >footer {
        margin-top: 0.75em;
        color: $primary;
        text-align: right;
        font-weight: $bold;
    }

    @include media-breakpoint-up("md") {
        font-size: 150%;
    }
}

.navigation {
    h1 {
        font-weight: $medium;
        margin: 0em 0.2em;

        @include media-breakpoint-up(md) {
            margin: 0.67em 0 0;
        }
    }

    nav {
        margin: 0 0.25em;

        @include media-breakpoint-up(md) {
            margin: 0 -0.25em;
        }

        a {
            text-transform: uppercase;
            color: $primary;
            position: relative;
            z-index: 9;
            border-bottom: 1px solid transparent;
            transition: border 700ms ease;
            line-height: 1;
            white-space: nowrap;
            display: inline-block;
            margin: 0.5em 0.25em;

            &.selected {
                border-bottom: 1px solid $primary;
                transition: border 700ms ease;
            }
        }
    }
}

.background-letter {
    z-index: -1;
    position: absolute;
    top: 2rem;
    left: 0;
    font-size: 40vh;
    font-weight: $bold;
    line-height: 0.75;
    opacity: 0.2;
    pointer-events: none;
    user-select: none;
    color: $secondary;
}

.content {
    position: relative;
    font-size: 18px;
    font-weight: $regular;
    line-height: 1.6;

    h1 {
        margin: 1.2rem 0;
    }
}

.btn {
    border-radius: 0px;
}

.button {

    display: block;
    padding: 10px;
    svg {
        max-height: 100%;
    }

    span {
        margin-left: 0.4rem;
    }

    &-container {
        display: flex;
        justify-content: center;
    }

    cursor: pointer;
    display: inline-flex;
    align-items: center;
    align-self: center;
    background: $primary;
    color: white;
    padding: 0.25rem 0;
    box-shadow: $box-shadow;
    font-weight: $medium;
    transition: all 150ms ease;
    transition-property: box-shadow transform;

    &:hover {
        transition: all 150ms ease;
        transition-property: box-shadow transform;
        color: white;
        transform: translateY(-1px);
        box-shadow: $box-shadow-hover;
    }

    &:active,
    &:focus {
        transform: none;
        color: white;
        box-shadow: $box-shadow;
    }

    &-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.25rem;

        svg {
            height: 1.5rem;
            width: 1.5rem;
        }

        .fa {
            padding: 0 0.25rem;
        }

        +* {
            padding: 0.3em 0.5em;
            border-left: 1px solid white;
        }
    }
}

ul {
    $ul-padding: 2em;
    list-style: none;
    padding-left: $ul-padding;

    li {
        line-height: 1.2em;
        min-height: 2.4em;

        +li {
            margin-top: 0.5em;
        }

        &:before {
            height: 1em;
            width: 1em;
            display: block;
            float: left;
            margin-left: -$ul-padding;
            background-position: center;
            background-repeat: no-repeat;
            background-size: 100%;

            content: "";
            background-image: url("/assets/dot.svg");
        }
    }

    &.unstyled {
        padding: 0;

        li {
            line-height: auto;
            min-height: auto;

            +li {
                margin-top: auto;
            }

            &:before {
                content: none;
            }
        }
    }

    &.tag-list {
        list-style: none;
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        li {
            line-height: 1em;
            min-height: 0;
            padding: 0.2em;
            background: $secondary-faded;
            border-radius: 0.3em;
            text-align: center;

            +li {
                margin-top: 0.25em;
            }

            &:before {
                content: none;
            }
        }
    }

    &.nav {
        flex-wrap: wrap;

        li {
            min-height: 0;

            &::before {
                content: none;
            }

            +li {
                margin-top: 0;
            }
        }

        &-pills {
            .nav-link {
                padding: 0;
                font-weight: $regular;

                &.active {
                    color: inherit;
                    background: none;
                    font-weight: $bold;
                    text-decoration: underline;
                }
            }
        }
    }
}

.share-this-popover {
    ul {
        li {
            min-height: auto;
            margin-top: 0;
            line-height: normal;
            height: auto;

            &:before {
                content: none;
            }
        }
    }
}

.footer {
    margin: 0;
    background: fade-out($secondary, 0.8);
    color: fade-out($black, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    .row {
        width: 100%;
    }

    .content {
        font-size: 0.7em;
        text-align: center;
        padding: 1em;
    }
}

.hidden {
    opacity: 0;
    visibility: hidden;
    z-index: -1000;
}

$timing: 450ms;

.content-wrapper {
    transition: transform $timing $easeInOutQuart;
}

.loader {
    transition: transform $timing $easeInOutQuart;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    background: $primary;
    z-index: $zindex-navbar + 90;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;

    transform: translateX(100%);
    visibility: hidden;
    opacity: 0;

    svg {
        stroke-dasharray: 2000;
        stroke-dashoffset: 2000;
    }

    .icon {
        max-height: 20vh;
    }

    &.slide-in,
    &.slide-out {
        opacity: 1;
        visibility: visible;

        svg {
            animation: dash 2s linear infinite alternate-reverse;
        }

        @keyframes dash {
            from {
                stroke-dashoffset: 0;
            }

            to {
                stroke-dashoffset: 2000;
            }
        }
    }
}

.slide-out {
    transition: transform $timing $easeInOutQuart;
    transform: translateX(-100%);
}

.slide-in {
    transition: transform $timing $easeInOutQuart;
    transform: translateX(0);
}

.fill-height {
    min-height: calc(100vh - #{$header-height + $footer-height});
}

.error {
    a {
        color: $primary;
        transition: opacity 150ms $easeInOutQuart;

        &:hover,
        &:active,
        &:focus {
            transition: opacity 150ms $easeInOutQuart;
            opacity: 0.7;
        }
    }

    h1 {
        margin-bottom: 0.5em;
    }

    display: flex;

    justify-content: center;
    align-items: center;
    flex-direction: column;

    img {
        max-height: 30em;
    }
}

.bordered-image {
    padding: 0.25em;
    border: 2px dashed $secondary;
}

.caption {
    font-size: 0.6em;
    margin-top: 0.75em;
    margin-bottom: 2em;
    opacity: 0.6;
    text-align: center;

    a,
    a:hover {
        color: $primary;
    }
}