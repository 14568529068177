@import "./_easing.scss";
@import "./_colors.scss";


$medium-height: 576px;
$tall-height: 768px;
$text-size: 16px;
$text-small: 13px;
$text-extra-small: 11px;
$text-xx-small: 10px;
$text-medium: 14px;
$text-big: 25px;

$header-height: 6rem;
$footer-height: 3.5rem;

$thin: 100;
$extralight: 200;
$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$extrabold: 800;
$heavy: 900;

$padding-base: 1.25rem;
$padding-double: $padding-base * 2;
$padding-half: $padding-base / 2;

$box-shadow: 0 .25rem .5rem fade-out(black, .9), 0 1px .25rem fade-out(black, .9);
$box-shadow-hover: 0 .5rem 1rem fade-out(black, .9), 0 .2rem .4rem fade-out(black, .9);
