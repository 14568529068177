@import url("https://fonts.googleapis.com/css?family=Kalam:700|Raleway:400,800,900&display=swap");

body {
  margin: 0;
  font-family: "Raleway";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: #f6f1e8;
  background-color: #f6f1e8;
}

html,
body,
#root {
  height: 100%;
}
