.modal {
  height: 100%;
  padding-top: 2em;
  touch-action: manipulation;

  .container-fluid {
    max-height: 100%;
    overflow: hidden;
    overflow-y: scroll;
  }

  .background-letter {
    position: absolute;

    top: -1vw;
    left: 50%;
    transform: translateX(-50%);
    font-size: 100vw;

    @include media-breakpoint-up(md) {
      font-size: 60vw;
      top: 0;
    }
  }

  h1 {
    text-align: center;
  }

  &-overlay {
    background: fade-out(black, 0.5);
    width: 100%;
    height: 100%;
    z-index: $zindex-modal;
    position: fixed;
    top: 0;
    left: 0;

    visibility: visible;
    opacity: 1;
    transition: all 700ms ease;
    transition-property: background visibility;

    display: flex;

    &.hidden {
      opacity: 1;
      background: transparent;
      visibility: hidden;
      transition: all 700ms ease;
      transition-property: background visibility;

      .modal-wrapper {
        transition: all 700ms $easeInOutQuart;
        transition-property: transform;
        transform: translateX(100%);
      }
    }
  }

  &-outside {
    width: 2%;

    @include media-breakpoint-up(md) {
      width: 10%;
    }
  }

  &-wrapper {
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 90%;
    }

    transition: all 700ms $easeInOutQuart;
    transition-property: transform;
    transform: translateX(0);
    background: white;
    display: flex;
    flex-direction: column;
  }

  &-close {
    cursor: pointer;
    color: $secondary;
    margin: 1rem 2rem .5rem;
    display: flex;
    align-items: center;
    text-transform: uppercase;

    span {
      margin-left: 1em;
    }
  }

  &-container {
    display: flex;
    align-items: center;
    flex: 1;
    flex-direction: row;

    -ms-overflow-style: none;
    overflow: hidden;

    section {
      padding: 0;
      position: relative;

      > .row { height: 100%; }
    }

    .container-fluid {
      display: flex;
      flex-direction: column;
      padding: 0;

      .row {
        flex: 1;
        margin: 0;
        @include media-breakpoint-up(md) {
          align-items: center;        
        }
      }
    }

    section {
      width: 100%;
      flex: 1 0 100%;
      justify-content: center;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-content: center;
    align-items: center;

    margin: 0 2rem;
    height: calc(100% - 4rem);
    overflow-x: hidden;
    overflow-y: auto;

    .row {
      min-width: 100%;
    }

    > .row + .row {
      margin-top: 2rem;
    }

    .content {
      padding: 0 1.5em;

      &.what-content {
        font-size: 1em;

        .icon-container {
          display: flex;
          justify-content: center;
        }

        .row + .row {
          padding: 0.5em;
        }

        justify-content: center;
        align-items: center;

        h1 {
          margin: 0.5rem;
        }

        .icon {
          @include media-breakpoint-up(md) {
            height: 11vh;
          }

          height: 5vh;
        }
      }

      .row + .row {
        padding-top: 2em;
      }
    }

    @include media-breakpoint-up(md) {
      height: 100%;
      margin: 0 8%;
    }
  }

  &-image {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;

    .outer {
      border: 3px dashed $secondary;
      box-shadow: 0.25rem 0.25rem 1rem fade-out(black, 0.7);
      border-radius: 50%;

      img {
        border-radius: 50%;
        border: 0.5rem solid white;
      }
    }
  }

  &-navigation {
    position: absolute;
    bottom: 1rem;
    right: 4rem;
    color: $primary;
    display: flex;
    align-items: center;
    line-height: 1;
    transform-origin: 100% 0;
    transform: rotate(90deg) translateX(100%);
    cursor: pointer;

    span {
      text-transform: uppercase;
      font-size: 75%;
      margin-left: 1em;
    }

    &.modal-previous {
      right: auto;
      left: 4rem;
      transform-origin: 0 0;
      transform: rotate(-90deg) translateX(-100%);
    }

    &.modal-close {
      margin: 0;
    }

    @include media-breakpoint-up(md) {
      top: 50%;
      bottom: auto;
      right: .5rem;
      transform: rotate(90deg) translateX(50%);

      &.modal-previous {
        right: auto;
        left: .5rem;
        transform: rotate(-90deg) translateX(-50%);
      }
    }
  }

  &-pager {
    position: absolute;
    bottom: 1rem;
    left: 6rem;
    right: 6rem;
    display: flex;
    justify-content: center;
    
    > button {
      color: $primary;
      background: none;
      border: none;
      width: 2rem;
      padding: 0;
      line-height: 2rem;

      &::before {
        content: "";
        display: inline-block;
        width: .75rem;
        height: .75rem;
        border: .2rem solid;
        border-radius: 100%;
      }
      &.current::before {
        background: currentColor;
      }
    }
  }

  .modal-title {
    margin-bottom: 20px;
  }

  .partner-box {
    &-container {
      @media screen and (min-width: 766px) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: auto;
        grid-auto-flow: row;
      }
    }

    min-height: 17rem;
    color: $text-secondary;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;

    @media screen and (max-width: 766px) {
      height: auto;
    }
    
    &:nth-child(1n) {
      background-color: $modal-box-primary;
    }
    &:nth-child(2n) {
      background-color: $modal-box-secondary;
    }
    &:nth-child(3n) {
      background-color: $modal-box-tertiary;
    }
    &:nth-child(4n) {
      background-color: $modal-box-secondary;
    }
    &:nth-child(5n) {
      background-color: $modal-box-tertiary;
    }
    &:nth-child(6n) {
      background-color: $modal-box-primary;
    }
    
    h3 {
      text-align: center;
      padding: 20px 0;
      font-size: 1.50rem;
    }

    .description-box {
      flex-grow: 1;
    }

    .description {
      text-overflow: ellipsis;
      overflow: hidden;
      -webkit-line-clamp: 4;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }

    .scopri-di-piu {
      color: $primary;
      font-weight: $bold;
    }

    .anchor-wrap {
      text-align: center;
      padding-bottom: 20px;

      @media screen and (max-width: 766px) {
        padding-top: 20px;
      }
    }
  }
}

.development-link,
.design-link,
.how-discover,
.how-design,
.how-deliver {
  cursor: pointer;
}
