@import './styles/main.scss';

$min-margin: 1rem;

svg {
  width: 11rem;
  margin: 1rem;
  margin-bottom: 0;
}

* {
  user-select: none;
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: -moz-none;
  -webkit-user-select: none;
}

input {
  user-select: initial;
}

::selection {
  background: transparent;
  color: inherit;
}

::-moz-selection {
  background: transparent;
  color: inherit;
}

#root {
  display: flex;
  flex-direction: column;
  overflow: auto;
}

main {
  display: flex;
  flex-direction: column;
  flex: 1 1 0px;
  height: 0px;


  h1 {
    font-family: Raleway;
    font-weight: bold;
    letter-spacing: 1.5px;
  }

  h1,
  h3,
  h4 {
    font-weight: bold;
  }

  h1,
  h3 {
    margin-bottom: 0;
  }

  h1,
  .subtitle {
    text-align: center;
  }

  h3 {
    font-size: 1.2rem;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.52px;
    color: #000000;
  }

  h4 {
    font-family: Raleway;
    font-size: 1.25rem;
    margin-top: 0.2rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.45px;
  }

  .subtitle {
    font-size: 1.5rem;
    margin-top: $min-margin / 2.5;
    margin-bottom: 2.7rem;
  }

}


.container-fluid {
  p {
    font-size: 1.2rem;
    padding-right: 1.3rem;
    line-height: 1.75rem;
  }

  flex-grow: 1;
  // padding: 0.75rem 0 0.75rem;
}

[class*="col-"] {
  padding: 0;
}

.row {

  // margin-right: -30px;
  // margin-left: -30px;
  .img-fluid {
    object-fit: scale-down;
    height: 2.7rem;
  }

  .first {
    margin-top: 0.7rem;
  }

  .second {
    margin-top: 0.7rem;
  }
}

footer {
  border-top: 6px solid #ff6600;
  background-color: white;
  box-shadow: 0 -5px 10px -5px;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 8px 16px;
  flex: 0 0 auto;

  .row {
    flex-wrap: wrap;

    .col {
      padding-right: 4px;
      padding-left: 4px;
      flex: 1 1 33.33%;
      width: 33.33%;

      @media screen and (min-width: 576px) {
        flex: 1 1 16.66667%;
        width: 16.66667%;
      }

      @media screen and (min-width: 769px) {
        flex: 1 1 11.1%;
        width: 11.1%;
      }
    }
  }

}

.imgContainer {
  position: relative;

}

.project-img {
  width: 100%;
}

.logo {
  width: 13rem;
}

@media screen and (max-width: 320px) {
  .project-img {
    width: 100%;
  }

  main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  h1 {
    font-size: 3rem;
  }

  h3 {
    font-size: 1.2rem;
  }

  h4 {
    font-size: 1.1rem;
  }

  h1,
  h3,
  h4 {
    text-align: center;
  }

  .subtitle {
    font-size: 1.5rem;
    text-align: center;
  }

  .imgContainer {
    text-align: left;
  }

  .container-fluid {
    margin: 0;
    padding: 0;

    p {
      font-size: 1.2rem;
      margin: 0;
      padding: 0;
      text-align: center;
    }
  }

  .first,
  .second {
    object-fit: scale-down;
    height: 2rem;
    padding: 0;
  }

  .project-img {
    width: 20rem;
  }


  .row {
    margin: 0;
  }

  .logo {
    width: 10rem;
  }
}

@media screen and (max-width: 375px) and (min-width: 320px) {
  main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  h1 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.2rem;
  }

  h4 {
    font-size: 1.1rem;
  }

  h1,
  h3,
  h4{
    text-align: center;
  }

  .first *,
  .second * {
    object-fit: scale-down;
    height: 1.6rem;
    padding: 0;
  }

  .container-fluid {
    margin: 0;
    padding: 0;

    p {
      font-size: 1.2rem;
      margin: 0;
      padding: 0;
      text-align: center;
    }
  }

  .logo {
    width: 10rem;
  }

  .row {
    margin: 0;
  }


  .project-img {
    width: 100%;
  }
}

@media screen and (max-width: 575px) and (min-width: 376px) {

  h1,
  h3,
  h4 {
    text-align: center;
    font-size: 3.75rem;
  }

  p {
    text-align: center;
  }

  .row {
    margin: 0;
  }

  .project-img {
    width: 100%;
  }
}

@media screen and (max-width: 991px) and (min-width: 769px) {

  .container-fluid {
    p {
      font-size: 1rem;
    }
  }

  h1 {
    font-size: 3rem;
  }

  .subtitle {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.3rem;
  }

  h4 {
    font-size: 1rem;
  }

  .logo {
    width: 12rem;
  }

}

@media screen and (max-width: 1010px) and (min-width: 992x) {}

@media screen and (max-width: 1199px) and (min-width: 1011px) {}

@media screen and (min-width: 1440px) {
  h1 {
    font-size: 4.2rem;
    text-align: center;
  }

  .subtitle {
    font-size: 2.1rem;
    text-align: center;
  }

  h3 {
    font-size: 1.5rem;
  }

  h4 {
    font-size: 1.4rem;
  }

  .container-fluid {
    p {
      font-size: 1.5rem;
      padding-right: 1.3rem;
    }

    flex-grow: 1;
    margin: 0 auto;
  }

}

.hidden-visually {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  border: 0;
}