.send-result-image {
    color: #ff6600;
    padding: 30px;
    margin: auto;
    justify-content: center;
    display: flex;

    img {
        max-height: 200px;
    }
}

.download-again {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 40px;

    .button {
        display: block;
        padding: 10px;
        padding-top: 8px;
        padding-bottom: 8px;
    }
}

.download-result-message-container {
    margin: auto;
}

.download-result-message {
    text-align: center;
    white-space: break-spaces;
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.6;
}