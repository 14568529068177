#root {
    main {
        display: flex;
        flex: 1 0 auto;
        height: auto;

        .projectsContainer {
            flex: 1 1 auto;
            height: auto;
            padding: 0.3rem 3.25rem 1.25rem 3.25rem;

            .row {
                height: 100%;

                @media screen and (min-height: 950px) and (min-width: 769px) {
                    max-height: 500px;                
                }
                

                .project[class*="col-"] {
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 1.2rem;
                    padding: 0 10px;
                    height: auto;

                    @media screen and (max-width: 575px) {
                        margin-bottom: 100px;
                    }

                    p {
                        padding-top: 1.375rem;
                        font-family: Raleway;
                        font-size: 1.125rem;
                        text-align: center;
                    }

                    h3 {
                        padding-top: 1.375rem;
                        font-family: Raleway;
                        font-size: 1.3125rem;
                        font-weight: 900;
                        text-align: center;
                    }

                    h4 {
                        font-family: Raleway;
                        font-size: 1.125rem;
                        font-weight: bold;
                        text-align: center;
                    }

                    .content {
                        flex-grow: 1;
                    }

                    .button-container {
                        justify-content: center;
                    
                        .button {
                            width: 8.4375rem;
                            height: 2.3125rem;

                            display: block;
                            padding: 10px;
                        }
                    }
                }



            }
        }
    }
}



@media screen and (min-width: 576px) {}

@media screen and (max-width: 991px) and (min-width: 769px) {}

@media screen and (max-width: 1010px) and (min-width: 992x) {}

@media screen and (max-width: 1199px) and (min-width: 1011px) {}

@media screen and (min-width: 1440px) {}